<template>
  <div>
    <loader v-if="loading" />
    <template v-else>

      <div class="mb-4">
        <!-- Info Guide -->
        <info-guide
          v-if="guideVisibleFirstTime"
          type="marketingBudgetExamples"
          visible
          @click="closeInfoFirstTime"
        />
        <!-- /Info Guide -->
        <div class="d-flex justify-content-end mb-4 margin-guide-not-visible">
          <erase-icon-button
            v-if="removeButtonVisible"
            @click="onDelete"
          />
        </div>
      </div>

      <template v-if="!isMarketingElementsEmpty">
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <distribution-of-marketing-budget />
        </ib-card>
        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <marketing-budget-over-time />
        </ib-card>
        <ib-card
          :animation="false"
          class="pt-4 pb-2"
        >
          <marketing-budget-table />
        </ib-card>
      </template>

      <empty-placeholder v-else />
    </template>

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="marketingBudgetExamples"
      :visible="guideVisible"
      @close="guideVisible = false;"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="marketing-budget"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Marketing Budget -->
    <dialog-add-marketing-budget
      v-if="canEdit"
      :marketing-element-action="marketingElementAction"
      :dialog-add-marketing-element-visible="dialogAddNewVisible"
      :on-edit-marketing-element="onEditMarketingElement"
      :on-copy-marketing-element="onCopyMarketingElement"
      :on-delete-marketing-element="onDeleteMarketingElement"
      @close-dialog-add-marketing-element="dialogAddNewVisible = false"
      @close-dialog-on-button="dialogAddNewVisible = false"
      @on-update-marketing-budget-view="onUpdateAddNewDialog()"
    />
    <!-- /Dialog Add Marketing Budget -->
  </div>
</template>

<script>
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import DialogAddMarketingBudget from '@/views/Home/StoryMode/Forecast/MarketingBudget/Dialogs/DialogAddMarketingBudget'
import DistributionOfMarketingBudget from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/MarketingCost/DistributionOfMarketingBudget'
import EmptyPlaceholder from '@/views/Home/StoryMode/FinancialPlan/Components/EmptyPlaceholder'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import EventBus from '@/event-bus'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'
import Loader from '@/components/Loader'
import MarketingBudgetOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/MarketingCost/MarketingBudgetOverTime'
import MarketingBudgetTable from '@/views/Home/StoryMode/Forecast/MarketingBudget/Tables/MarketingBudgetTable'
import MixinAiBuddyDrawer from '@/mixins/aiBuddyDrawer'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'ExpensesMarketingCosts',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    DialogAddMarketingBudget,
    DistributionOfMarketingBudget,
    EmptyPlaceholder,
    EraseIconButton,
    Loader,
    MarketingBudgetOverTime,
    MarketingBudgetTable
  },

  mixins: [MixinApiCalculations, MixinGuide, MixinIdeaRoles, MixinDeleteIdeaExamples, MixinAiBuddyDrawer],

  data () {
    return {
      loading: false,
      dialogAddNewVisible: false,
      marketingElementAction: null
    }
  },

  computed: {
    ...mapState(['idea']),

    isMarketingElementsEmpty () {
      return this.idea.storyMode.forecast.marketingBudget.marketingElements.length === 0
    },

    removeButtonVisible () {
      return this.canEdit && this.marketingCostHasItemsFromTemplate
    }
  },

  mounted () {
    this.openGuide('marketingBudgetFinancialPlanGuide')
    EventBus.$on('add-edit', () => {
      this.onShowAddNewDialog()
    })

    EventBus.$on('open-info', () => {
      this.guideVisible = true
    })

    EventBus.$on('close-info-first-time', () => {
      this.guideVisibleFirstTime = false
    })

    EventBus.$on('remove-examples', () => {
      this.deleteExamples()
    })
  },

  beforeDestroy () {
    EventBus.$off('add-edit')
    EventBus.$off('open-info')
    EventBus.$off('remove-examples')
    EventBus.$off('close-info-first-time')
  },

  methods: {
    ...mapActions('idea', ['deleteStoryModeMarketingElement']),

    onShowAddNewDialog () {
      this.getMarketingBudget()
      this.dialogAddNewVisible = true
    },

    onUpdateAddNewDialog () {
      this.loading = true
      Promise.all([
        this.getMarketingBudget(),
        this.getTotalCosts(),
        this.getProfitAndCashFlow()
      ])
        .then(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },

    onEditMarketingElement (marketingElement) {
      this.marketingElementAction = Object.assign({}, marketingElement)
    },

    onCopyMarketingElement (marketingElement) {
      this.marketingElementAction = Object.assign({}, marketingElement)
      this.marketingElementAction.id = 0
    },

    onDeleteMarketingElement (marketingElement) {
      this.loading = true
      this.deleteStoryModeMarketingElement(marketingElement.id)
        .then(() => {
          this.getMarketingBudget()
            .then(() => { this.loading = false })
            .finally(() => { this.loading = false })
        })
        .catch(() => { this.loading = false })
    },

    deleteExamples () {
      this.deleteExamplesMixin('marketing-elements')
        .then(_ => { this.onUpdateAddNewDialog() })
    }
  }
}
</script>
